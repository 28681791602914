

































































































































import SfHeading from '/storefrontUI/components/components/atoms/SfHeading/SfHeading.vue';
import SfInput from '/storefrontUI/components/components/atoms/SfInput/SfInput.vue'
import SfButton from '/storefrontUI/components/components/atoms/SfButton/SfButton.vue';
import SfCheckbox from '/storefrontUI/components/components/molecules/SfCheckbox/SfCheckbox.vue';
import {
  ref,
  computed,
  defineComponent,
  useRouter,
  useContext,
  useFetch,
  onMounted,
} from '@nuxtjs/composition-api';
import {
  required, min, email,
} from 'vee-validate/dist/rules';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { useGuestUser } from '~/composables';
import useCart from '~/modules/checkout/composables/useCart';
import { useUser } from '~/modules/customer/composables/useUser';
import { getItem, mergeItem } from '~/helpers/asyncLocalStorage';
import { customerPasswordRegExp, invalidPasswordMsg } from '~/modules/customer/helpers/passwordValidation';

extend('required', {
  ...required,
  message: 'This field is required',
});
extend('min', {
  ...min,
  message: 'The field should have at least {length} characters',
});
extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('password', {
  message: invalidPasswordMsg,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  validate: (value) => customerPasswordRegExp.test(value),
});

export default defineComponent({
  name: 'UserAccount',
  components: {
    SfHeading,
    SfInput,
    SfButton,
    SfCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const router = useRouter();
    // @ts-expect-error Recaptcha is not registered as a Nuxt module. Its absence is handled in the code
    const { app, $recaptcha, $config } = useContext();
    const isRecaptchaEnabled = ref(typeof $recaptcha !== 'undefined' && $config.isRecaptcha);

    const {
      attachToCart,
      loading: loadingGuestUser,
      error: errorGuestUser,
    } = useGuestUser();

    const { cart } = useCart();

    const {
      load,
      loading: loadingUser,
      register,
      login,
      user,
      isAuthenticated,
      error: errorUser,
    } = useUser();

    const isFormSubmitted = ref(false);
    const createUserAccount = ref(false);
    const loginUserAccount = ref(false);
    const loading = computed(() => loadingUser.value || loadingGuestUser.value);

    const canMoveForward = computed(() => !(loading.value));
    const anyError = computed(() => errorUser.value.login || errorUser.value.register || errorGuestUser.value.attachToCart);

    type Form = {
      firstname: string,
      lastname: string,
      email: string,
      password: string,
      is_subscribed: boolean,
      recaptchaToken?: string,
      recaptchaInstance?: string,
    };

    const form = ref<Form>({
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      is_subscribed: false,
    });

    const handleFormSubmit = (reset: () => void) => async () => {
      if (isRecaptchaEnabled.value) {
        $recaptcha.init();
      }

      if (!isAuthenticated.value) {
        if (isRecaptchaEnabled.value && createUserAccount.value) {
          const recaptchaToken = await $recaptcha.getResponse();
          form.value.recaptchaToken = recaptchaToken;
          form.value.recaptchaInstance = $recaptcha;
        }

        await (
          createUserAccount.value
            ? register({ user: form.value })
            : attachToCart({ email: form.value.email, cart })
        );
      }

      if (loginUserAccount.value) {
        const recaptchaParams : { recaptchaToken?: string } = {};
        if (isRecaptchaEnabled.value) {
          recaptchaParams.recaptchaToken = await $recaptcha.getResponse();
        }

        await login({
          user: {
            email: form.value.email,
            password: form.value.password,
            ...recaptchaParams,
          },
        });
      }

      if (!anyError.value) {
        await mergeItem('checkout', { 'user-account': form.value });
        await router.push(app.localeRoute({ name: 'shipping' }));
        reset();
        isFormSubmitted.value = true;
      }

      if (isRecaptchaEnabled.value) {
        $recaptcha.reset();
      }
    };

    useFetch(async () => {
      if (user.value === null) {
        await load();
      }
      if (isAuthenticated.value) {
        form.value.firstname = user.value.firstname;
        form.value.lastname = user.value.lastname;
        form.value.email = user.value.email;
      }
    });

    onMounted(async () => {
      const checkout = await getItem('checkout');
      if (checkout && checkout['user-account']) {
        const data = checkout['user-account'];
        form.value.email = data.email;
        form.value.firstname = data.firstname;
        form.value.lastname = data.lastname;
      }
    });

    return {
      canMoveForward,
      createUserAccount,
      errorUser,
      form,
      handleFormSubmit,
      isAuthenticated,
      isFormSubmitted,
      loading,
      loginUserAccount,
      user,
      isRecaptchaEnabled,
    };
  },
});
